/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Quick Access Component instance
 * @author Rishabh
 * @class QuickAccessV2
 * @classdesc Ace quick access component V2 to inherit the quicka ccess core component
 * @extends QuickAccessV2
 * @version 1.0
 */
import { QuickAccessV2, TagManager } from "@accor/ace-ui-core";

export default class SofitelQuickAccessV2 extends QuickAccessV2 {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    this.stickyBookingHeight = 0;
    this.lastScrollTarget = null;
    this.stickyAnchorNavigationHeight = null;
    this.quickAccessNavItems = this.componentHost.querySelectorAll(
      ".cmp-ace-anchor-navigation__tabs__anchors ul li",
    );
    this.tagManagerHandler();
  }

  initialize() {
    super.initialize();
    this.observeStickyBooking();
    this.scrollWithSticky();

    window.addEventListener(CoreJS.DomEventConstants.SCROLL, () => {
      this.flagSticky = window.scrollY > this.componentHost.offsetTop;
      this.sticky();
    });
  }

  tagManagerHandler() {
    const dataLayerValue = document
      .querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
      this.quickAccessNavItems.forEach((element, index) => {
        element?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const dataEvent = {
            event: "GA4event",
            eventName: "menu_inpage",
            event_data: {
              pagename: this.dataLayerJson?.pageName,
              menu_position: index + 1,
              menu_label: element?.innerText?.trim()?.toLowerCase(),
            },
          };
          TagManager.trackEvent(dataEvent);
        });
      });
    }
  }

  observeStickyBooking() {
    const stickyBookingContainer = document.querySelector(
      ".sticky-booking-engine",
    );
    if (!stickyBookingContainer) return;

    const observer = new MutationObserver(() => {
      const stickyBooking = document.querySelector(
        ".sticky-booking-engine.visible",
      );
      if (stickyBooking) {
        this.stickyBookingHeight = stickyBooking.offsetHeight;

        if (this.lastScrollTarget) {
          this.scrollToTarget(this.lastScrollTarget);
          this.lastScrollTarget = null;
        }
      }
    });

    observer.observe(stickyBookingContainer, { attributes: true });
  }

  scrollWithSticky() {
    this.componentHost.addEventListener("click", (event) => {
      const anchor = event.target.closest('a[data-cmp-hook-dropdown="filter"]');
      if (!anchor) return;

      event.preventDefault();
      const targetId = anchor.getAttribute("href").substring(1);
      if (targetId) {
        this.lastScrollTarget = targetId;
        this.scrollToTarget(targetId);
      }
    });
  }

  scrollToTarget(targetId) {
    const targetElement = document.getElementById(targetId);
    if (!targetElement) return;

    if (!this.stickyAnchorNavigationHeight) {
      const stickyNav = document.querySelector(".quick-access");
      this.stickyAnchorNavigationHeight = stickyNav
        ? stickyNav.offsetHeight
        : 0;
    }

    let top =
      targetElement.getBoundingClientRect().top +
      window.scrollY -
      this.stickyAnchorNavigationHeight;

    if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      top -= this.stickyBookingHeight;
    }

    window.scrollTo({ top, behavior: "smooth" });
  }
}

// Register the QuickAccess component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  QuickAccessV2.CLASS_NAMESPACE,
  SofitelQuickAccessV2,
  true,
);
